.ml-30 {
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.d-flex {
    display: flex;
    justify-content: end;
}

.pos-ab {
    position: absolute !important;
    bottom: 64px;
}

.pos-logo {
    position: absolute !important;
    bottom: 12px;
}

.copyDrop1 {
    width: 400px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 9px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    font-family: 'Futura PT';
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 8px top 53%;
}