/* input {
    position: relative;
    width: 150px; height: 20px;
    color: white;
}

input:before {
    position: absolute;
    top: 3px; left: 3px;
    content: attr(data-date);
    display: inline-block;
    color: black;
}

input::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
}

input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    opacity: 1;
} */
.font-face-futura-footer {
    font-family: "Futura PT";
    font-weight: 600;
}
.font-face-futura-placeholder {
    font-family: "Futura PT";
    font-weight: 400;
    color: #535353;
    font-size: 15px;
}
.footer-left-area {
    display: flex;
    justify-content: flex-start;
    width: calc(50% - 100px);
    margin-left: 100px;
}
.t-left{
    text-align: left;
    line-height: 3.3rem;
}
.date-picker {
    color: #535353;
    height: 30px;
    width: 180px;
    background: #F5F5F5;
    border-radius: 9px;
    border: none;
    padding: 0px 8px;
    font-size: 16px;
    font-family: 'Futura PT';
}
.none-selector{
    display: none;
}
.show-selector{
    display: block;
}
.sticky-selection{
    position: absolute;
    bottom: 55px;
    overflow: hidden;
    height: 108px;
    width: 200px;
    border-radius: 9px;
    padding: 4px 10px 0px 10px;
    cursor: pointer;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
}
.sticky-selection2{
    position: absolute;
    bottom: 55px;
    overflow: hidden;
    height: 214px;
    width: 250px;
    border-radius: 9px;
    padding: 6px 10px 0px 10px;
    cursor: pointer;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
}
.option {
    border-bottom: 1px solid lightgrey;
}
.sticky-select{
    position: absolute;
    bottom: 55px;
    overflow: hidden;
    height: 142px;
    width: 260px;
    border-radius: 10px;
    padding: 6px 10px 0px 10px;
    margin: 0px 0px 0px 346px;
}
.drop {
    width: 260px;
    height: 30px;
    margin: 15px 0px 0px 20px;
    padding: 4px 15px 5px 10px;
    gap: 5px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}
.claims {
    margin: 15px 0px 0px 20px;
}
.claimsDrop {
    margin: 13px 0px 0px 20px;
    height: 30px;
    width: 260px;
    padding: 4px 15px 5px 10px;
    gap: 5px;
    background-color: #FFFFFF !important;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%
}
.claimInput {
    margin: -3px 28px 0px -3px;
    border: none;
    width: 225px;
    color: transparent;
    cursor: pointer;
}
.claimInput:focus {
    outline: none;
}
.searchInput {
    margin-top: 13px; 
    margin-left: 13px;
    float: left;
    height: 30px; 
    width: 270px;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}
.searchInput:focus {
    outline: none;
}
.foot-calendar {
    display: flex;
    align-items: center;
    float: right;
    margin: 10px 65px 0px 0px;
}
.react-datepicker-wrapper {
    width: 100% !important;
}