
.autoRunRuleTable {
    width: 100%;
}
.MuiTab-labelIcon {
    min-height: 55px !important;
}
.autoRunRuleTable thead {
    background: #24476A;
    border-radius: 9px;
    height: 70px;
    color: #fff;
}

.trnsactionTab {
    margin-right: 159px;
    font-weight: 400;
    font-family: 'Futura PT';
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #535353;
}
.transctionTabText {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 42.5%;
    border-radius: 50%;
    top: calc(50% - 25px/2);
    background: #5CB02C;
    font-family: 'Futura PT';
    font-size: 20px;
    line-height: 28px;
    color: #f9f9f8;
}
.font-face-futura {
    font-family: 'Futura PT';
}
.tabAutoRun {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 44%;
    border-radius: 50%;
    top: calc(50% - 25px/2);
    background: #5CB02C;
    font-family: 'Futura PT';
    font-size: 20px;
    line-height: 25px;
    color: #f9f9f8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reportHead {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    grid-gap: 20px;
    gap: 20px;
    width: 92%;
    height: 45px;
    background: #24476A;
    border-radius: 15px;
    margin: 20px 0px 0px 115px;
}

.mySwiper {
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width:366px;
    height: 150px;
    background: #f7f1f06b;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    margin-top: 30px;
    margin-left: 21px;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #535353;
}

.mySwiperCard {
    width: 100%;
    margin-left: 100px;
}

.reportCard {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    /* color: #FFFFFF; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FAFAFA !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
    height: 482px;
    margin-top: 3rem !important;
    width: 92%;
    margin-left: 115px;
}

.autoRunCard {
    font-family: 'Futura PT';
    align-items: center;
    padding: 0px 0px 10px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
    height: 545px;
    margin-top: 3rem !important;
    width: 92%;
    margin-left: 115px;
}

.reportCardHead {
    padding: 15px 11px 12px 32px !important;
    width: 100%;
    height: 44px !important;
    background: #24476A !important;
    border-radius: 15px 15px 0px 0px !important;
}

.planCardHead h5 {
    font-size: 20px;
}

.managerRule {
    width: calc(108% - 155px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-left: 9px;
    position: relative;
    height: 412px;
    font-family: 'Futura PT';
}

.managerRule .innerdata {
    padding: 18px;
    height: 48px;
}

.manageTableRule {
    margin-bottom: 1.8rem;
}

.manageTableRule thead {
    width: 90%;
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}

.manageTableRule tbody {
    display: block;
    max-height: 35vh;
    /* height: 25vh; */
    overflow-y: scroll;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
}

.manageTableRule thead,
.manageTableRule tbody tr {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.noRecord {
    font-size: 16px;
    color: #954437;
}

.tabmanagerRule {
    width: 92.5% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-left: 95px;
}

.tablemanagerRule .innerdata {
    padding: 18px;
    height: 48px;
}

.managerRuleTable thead {
    width: 90%;
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}

.managerRuleTable tbody {
    display: block;
    max-height: 65vh;
    height: 10vh;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.managerRuleTable thead,
.managerRuleTable tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.block_center>div {
    text-align: center;
    width: 100% !important;
    height: 87% !important;
}


.block_center p {
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 1rem;
}


.dateRange {
    box-sizing: border-box;
    padding: 5px 15px 7px 10px;
    width: 262.5px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 9px;
    text-align: center;
    font-family: 'Futura PT';
}
.dataforRange {
    margin-left: 900px;
    text-align: center;
    font-family: 'Futura PT';
}

.variableCard {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FAFAFA !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
    height: 697px;
    margin-top: 3rem !important;
    width: 91%;
    margin-left: 122px;
}

.breakDown {
    width: 674px;
    margin-left: 20px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 9px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    text-align: center;
}

.breakDownType {
    margin-left: 28px;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

.variableViolation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 30px;
    gap: 10px;
    width: 837px;
    height: 40px;
    background: #24476A;
    border-radius: 15px 15px 0px 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.violationCard {
    padding: 10px;
    gap: 10px;
    width: 837px;
    height: 550px;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
}

.providersViolation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 30px;
    gap: 10px;
    width: 800px;
    margin-left: 28px;
    height: 40px;
    background: #24476A;
    border-radius: 15px 15px 0px 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.providerCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    width: 798px;
    margin-left: 28px;
    height: 530px;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.cleanPieChart {
    margin-top: 16%;
}
.cleanPieChart .claimsPerc {
    text-align: center;
    font-size: 55px;
    font-weight: 600;
    font-family: 'Futura PT';
    color: #5CB02C;
}
.cleanPieChart .approveText {
    margin-top: 10% !important;
    text-align: center;
    color: #5CB02C;
    font-size: 36px;
    font-family: 'Futura PT';
}
.cleanPieChart .violatedPerc {
    margin-top: 10% !important;
    text-align: center;
    font-size: 55px;
    font-weight: 600;
    font-family: 'Futura PT';
    color: #3681A9;
}
.cleanPieChart .violatedText {
    margin-top: 10% !important;
    text-align: center;
    font-size: 36px;
    font-family: 'Futura PT';
    color: #3681A9;
}


/* .css-6hp17o-MuiList-root-MuiMenu-list {
    width: 260px ;
    height: 220px ;
} */
.mySwiperCard .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}
.mySwiperCard .swiper{
    overflow: unset;
}
.mySwiperCard .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
     top: 12rem;
     transform: translate(-48%);
     left: 48%;
}
.mySwiperCard .swiper-pagination-bullet {
    background: #ffffff;
    opacity: 1;
    border: 1px solid #adadad;
}
.mySwiperCard .swiper-pagination-bullet-active {
    background: #D8D8D8;
    opacity: 1;
    border: 1px solid #adadad;
}
.swipeCard{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.swipeDetail {
    text-align: left;
    font-size: 25px;
    font-family: 'Futura PT';
}
.swipeDetail p{
    font-size: 19px;
    margin-bottom: 0.8rem;
}
.swipeDetail .perc{
    color: #5CB02C;
    font-size: 44px;
    font-weight: 600;
    margin-top: 15px;
    display: block;
    text-transform: uppercase;
}
.custom-swiper .mySwiper{
    padding: 20px;
}
.w31{
    width: 40%;
}
.w32, .w33{
    width: 30%;
}
.percStatus {
    font-family: 'Futura PT';
    font-size: 17px;
    line-height: 19px;
    font-weight: 600;
    display: flex;
    letter-spacing: -0.02em;
}

.swipeDetail .percRed{
    color: #954437;
    font-size: 40px;
    font-weight: 600;   
    margin-top: 15px;
    display: block;
    text-transform: uppercase;
}
.total{
    font-family: 'Futura PT';
    font-size: 15px;
    margin-top: 21px;
    text-transform: uppercase;
}
.swipeImg img {
    width: 150px;
}
.mrt-top {
    margin-top: 1.2rem !important;
}


.Mui-selected span.MuiTab-wrapper {
    color: #5CB02C !important;
}

.MuiTab-wrapper {
    margin-left: 32px;
    color: #535353
}
.swiper-button-next, .swiper-button-prev {
    text-rendering: auto;
  }

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset,120px) !important;
    left: auto;
}

.swiper-button-next:after {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
    content: '>' !important;
    letter-spacing: -4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: -33px;
    width: 37px;
    height: 37px;
    background: #24476A;
    color: white;
    border: 0px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 100px;
    flex: none;
    order: 2;
    margin-top: 24px;
}
.swiper-button-prev:after {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
    content: '<' !important;
    letter-spacing: 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: -33px;
    width: 37px;
    height: 37px;
    background: #24476A;
    color: white;
    border: 0px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 100px;
    flex: none;
    order: 2;
    margin-top: 24px;
    margin-right: 57px;
}
/**
*Loader pop up style start
*/
.MuiDialog-paper {
    overflow: visible !important;
}

.avatarRep2 , .avatarRep { 
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left: 50%;
    transform: translate(-50% , -50%);
    top: 0px !important;
    background: #FFFFFF;
    border-radius: 50% !important;
}

/* .avatarRep {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 45%;
    top: 233px !important;
    background: #FFFFFF;
    border-radius: 50% !important;
} */
.ruleoutLogoRep {
    width: 75px;
    height: 100px;
    margin: 45px 0px 0px -37px;
    position: absolute;
}

.dialog-boxRep {
    padding: 100px 0px 0px;
    height: 271px;
}
.dialog-boxRep2 {
    padding: 100px 0px 0px;
    height: 400px;
}
.dialogTitleRep {
    font-size: 50px;
    line-height: 75px;
    color: #535353;
    letter-spacing: -0.04em;
}
.dialogTextRep{
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #535353;
    font-family: 'Futura PT' !important;
}
.dialogBtnRep {
    padding: 3px 0px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
}
.dialogCancel {
    color: #954437 !important;
    width: 140px !important;
    height: 140px !important;
    margin: 15px;
}

/**
*Loader pop up style end
*/
.fixed-dropdown-btn{
    position: fixed !important;
    top: 66%;
    margin-left: -13px; 
    /* max-height: 220px !important; */
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 0px 0px 0px 5px !important;
    width: 253px;
    color: white !important;
    height: 31px;
    background: #5CB02C !important;
    border-bottom: -1px solid #D8D8D8 !important;
    border-radius: 9px !important;
}

.noOptionBtn{
    position: fixed;
    margin-top: 7px;
    margin-left: -15px;
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 5px 0px 5px 5px;
    width: 271px;
    color: white !important;
    height: 31px;
    background: #5CB02C !important;
    border-bottom: -1px solid #D8D8D8 !important;
    border-radius: 9px !important;
}
.reportsDropdown {
    box-sizing: border-box;
    padding: 5px 15px 7px 10px;
    gap: 5px;
    width: 262.5px;
    height: 30px;
    background-color: #FFFFFF !important;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px !important;
    margin-top: 3px;
    margin-left: 0px;
    font-family: 'Futura PT';
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%
    }
    
.customProvider {
    background: #fff;
    position: relative;
    z-index: 999;
    /* width: 253px;
    left: 34.5%; */
    padding: 0;
    cursor: pointer;
    font-family: 'Futura PT';
    left: 5px;
    /* border: 1px solid #D8D8D8; */
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    position: absolute;
}
.customInsurance {
    background: #fff;
    position: relative;
    z-index: 999;
    /* width: 253px;
    left: 39.5%; */
    padding: 0;
    left: 3px;
    cursor: pointer;
    font-family: 'Futura PT';
    /* border: 1px solid #D8D8D8; */
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    position: absolute;
}
.customLocation {
    background: #fff;
    position: relative;
    z-index: 999;
    /* width: 253px;
    left: 38.5%; */
    padding: 0;
    left: 3px;
    cursor: pointer;
    font-family: 'Futura PT';
    /* border: 1px solid #D8D8D8; */
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    position: absolute;
}

.activeGreen {
    background-color: #5CB02C;
    color: #FFFFFF;
    font-family: 'Futura PT';
}

.customList {
    text-decoration: none;
    list-style: none;
    z-index: 99999999;
    display: block;
    padding: 0px 5px 0px 5px;
    width: 100%;
    max-height: 186px;
    overflow: auto;
    margin-bottom: 0px;
    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-radius: 9px 9px 0px 0px;
    border-bottom: 0;
}

.customProvider button{
    box-sizing: border-box;
    padding: 5px 0px 5px 5px;
    width: 240px;
    height: 31px;
    background: #5CB02C;
    border-radius: 9px;
    color: #FFFFFF;
    border: none;
    margin-top: -3px;
    margin: 0px 5px 6px 3px;
}
.customLocation button{
    box-sizing: border-box;
    padding: 5px 0px 5px 5px;
    width: 240px;
    height: 31px;
    background: #5CB02C;
    border-radius: 9px;
    color: #FFFFFF;
    border: none;
    margin-top: 8px;
    margin-bottom: 6px;
}
.customInsurance button{
    box-sizing: border-box;
    padding: 5px 0px 5px 5px;
    width: 240px;
    height: 31px;
    background: #5CB02C;
    border-radius: 9px;
    color: #FFFFFF;
    border: none;
    margin-top: 8px;
    margin-bottom: 6px;
}
 .customList li {
    padding: 8px;
    border-bottom: 1px solid #D8D8D8;
    font-size: 13px;
    text-align: left;
    width: 240px;
 }
 .btncover {
    border: 1px solid #D8D8D8;
    border-radius: 0px 0px 9px 9px;
    border-top: 0;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding-top: 0px;
    padding-bottom: 0px;
    outline: 0;
    background: #FFFFFF;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color:  #5CB02C !important;
    color: white;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    /* position: relative; */
    padding-top: 0px !important;
    padding-bottom: 16px !important;
}

.dropGroup {
    width: 268px;
 margin: 0rem 1rem;
 position: relative;
}
.divbox {
    display: flex;
    justify-content: start;
    margin-left: 2rem;
}
.divbox label {
    padding-left: 2rem;
    padding-top: 5px;
}

@media screen and (min-width:320px) and (max-width:767px) {
    .innerdata {
        display: flex;
    }
}

@media screen and (max-width:1800px) {
    .manageTableRule tbody {
        max-height: 51vh;
    }

    .iconBox {
        width: 550px;
        left: calc(50% - 600px/2 - 490px);
    }

    .trigDiv {
        width: 92%;
    }

    .trigSelect {
        margin: -45px 60px 20px 0px;
    }

    .tablemanagerRule {
        width: calc(100% - 137px) !important;
    }

    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
    .trnsactionTab {
        margin-right: 58px;
    }
    .dataforRange {
        margin-left: 875px !important;
        margin-right: 0.5rem;
    }
    .mySwiper {
        width: 355px;
    }
    .swipeImg img {
        width: 130px;
    }
    .providersViolation {
        width: 750px;
    }
    .providerCard{
        width: 780px;
    }
    .variableViolation{
        width: 750px;
    }
}

@media screen and (max-width:1536px) {
    .dataforRange {
        margin-left: 770px !important;
        margin-right: 0.5rem;
    }
    .w32, .w33 {
        width: 40% !important;
    }
    .w31 {
        width: 35% !important;
    }
    .manageTableRule tbody {
        max-height: 46vh;
    }

    .iconBox {
        width: 530px;
        left: calc(50% - 600px/2 - 380px);
    }

    .trigDiv {
        width: 91.25%;
    }

    .trigSelect {
        margin: -45px 50px 20px 0px;
    }

    .tablemanagerRule {
        width: calc(100% - 132px) !important;
    }

    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
    .mySwiper {
        width: 315px;
    }
    .swipeDetail p {
        font-size: 16px;
    }
    .swipeDetail .perc {
        font-size: 40px;
    }
    .swipeImg img {
        width: 130px;
    }
    .breakDown {
        width:500px;
    }
    .providersViolation {
        width: 650px;
    }
    .providerCard{
        width:680px;
    }
    .variableViolation{
        width:650px;
    }
    .violationCard{
        width: 650px;
    }
}

