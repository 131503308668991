.connection-banner{
    background-color: #954437;
    height: 65px;
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Futura PT';
    position: fixed;
    z-index: 9;
}

.two-line-ellipsis {
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.one-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mr-50 {
    margin-right: 50px;
}

.noRecord {
    font-size: 16px;
    color: #954437;
}

table th {
    white-space: unset !important;
}

.font-face-futura-thead {
    font-family: "Futura PT";
    font-weight: 450;
    font-size: 15px;
}
.font-face-futura {
    font-family: "Futura PT";
    font-weight: 450;
}
.font-face-futura-medium {
    font-family: "Futura Medium PT";
}
.font-face-futura-book {
    font-family: "Futura Book PT";
}

.font-face-underground {
    font-family: 'P22UndergroundBook';
    font-weight: 400;
}

.font-face-roboto {
    font-family: 'Roboto';
    font-weight: 400;
}

.rule-box1 {
    max-height: 250px;
    overflow-y: auto;
    max-width: 260px;
    overflow-x: hidden;
    word-break: break-word;
}
.ruleBtn{
    width: 165px;
    height: 40px;
    background-color: #24476A !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

.runSelected {
    background-color: #24476a !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    float: left;
    border-radius: 9px !important;
    width: 120px;
    height: 35px;
}
.rulesRan {
    background-color: #5CB02C !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    float: left;
    border-radius: 9px !important;
    width: 100px;
    height: 30px;
}

.autoRun {
    width: 20px;
    height: 20px;
    border-radius: 20px !important;
}

.table-respo {
    position: relative;
}

.fixtablehead {
    margin: 1rem;
}

.foot-flex {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background-color: #F2F2F2;
    border-radius: 9px;
}

.foot-right {
    width: 100%;
    max-width: 420px;
    text-align: left;
}

.custom-size {
    padding: 0 !important;
    border-radius: 9px;
    background: #fcfcfc;
    border: 1px solid #efefef;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
    /* box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px; */
}

.innerdata div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
    margin-bottom: 4rem;
    margin-top: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.custom-table thead {
    background: #24476A;
    border-radius: 10px;
    height: 70px;
}

.custom-table thead th {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    position: sticky;
    top: 0;
    background: #24476a;
    z-index: 2;
}

.custom-table tr {
    width: 98%;
    margin: 1rem;
}

/* .custom-table tbody tr{
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
} */
.custom-table tr td {
    font-size: 12px;
    padding: 0px 0px 10px 0px;
}

.custom-table th:nth-child(1) {
    border-radius: 9px 0px 0px 9px !important;
    padding: 0px 40px;
}

.custom-table th:last-child span {
    margin-right: 3.5rem;
}

.rule-table {
    margin-bottom: 3rem;
}

.rule-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    color: white;
    text-align: center;
    border-radius: 9px;
}

.rule-table thead {
    background: #24476A;
    border-radius: 9px;
    height: 70px;
    color: #fff;
    position: relative;
}

.rule-table tbody {
    display: block;
    max-height: 56vh;
    height: 56vh;
    overflow-y: scroll;
    margin-bottom: 4rem;
    padding-right: 15px;
}

.rule-table thead,
.rule-table tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.opentoggle2 {
    width: calc(100% - 0px);
    /* width: calc(100% - 440px); */
}

.table-bg td p {
    margin-bottom: 0rem !important;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

/* .table-bg:nth-child(odd){
      background: #F2F2F2;
      border-radius: 9px;
      box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
  }
  .table-bg:nth-child(even){
    background: #CEF8B6;
    border-radius: 9px;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
} */

.innerdata {
    padding: 18px;
    height: 58px;
}

.table-row td {
    border-bottom: 0px !important;
    border-top: 0px !important;
    white-space: nowrap;
    color: #535353;
    font-size: 12px;
    font-family: 'Futura PT';
    font-weight: 600;
    padding: 6px 0px 6px 0px;
}

.table-row:nth-child(even) .innerdata {
    background-color: #CEF8B6;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
}

.table-row:nth-child(odd) .innerdata {
    background-color: #F2F2F2;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
}

.dblock {
    display: block;
}

.table-row:nth-child(even) .innerdata .dblock {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-row:nth-child(odd) .innerdata .dblock {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tableresponsivecustom {
    transition: all .3s ease;
    width: calc(100% - 30px);
    position: relative;
}

.tableresponsivecustom .custom-table {
    margin-top: 0;
}

.tableresponsivecustom.opentoggle1 {
    width: calc(100% - 290px);

}

.MuiPaper-elevation1 {
    box-shadow: inset -20px -13px 0px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%) !important;
}

.small-label {
    font-size: 13px;
    line-height: 1.2rem;
    text-align: left;
}

.mostR {
    text-align: left;
    line-height: 1.4rem;
    margin: 0.5rem 0rem 0rem 0.5rem;
}

/* insurance dialog content css starts here */

.questionMark {
    margin-top: 25px;
    width: 150px;
    height: 150px;
}

.nevermind{
    padding: 3px 0px;
    width: 257.5px !important;
    height: 50px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}
.submit{
    padding: 3px 0px;
    width: 257.5px !important;
    height: 50px !important;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

/* insurance dialog content css ends here */

/* --------------- charges dialog title ---------------- */

.ruleoutLogo {
    width: 75px;
    height: 100px;
    margin: 45px 0px 0px -37px;
    position: absolute;
}
.dialog-box {
    padding: 100px 0px 0px;
    height: 242px;
}
.dialog-box2 {
    padding: 100px 0px 0px;
    height: 400px;
}
.dialog-box3 {
    padding: 100px 0px 0px;
    height: 310px;
}
.dialog-box4 {
    padding: 100px 0px 0px;
    height: 430px;
}

.dialogTitle {
    font-size: 50px;
    line-height: 75px;
    color: #535353;
    letter-spacing: -0.04em;
}

.dialogText{
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #535353;
    font-family: 'Futura PT' !important;
}
/* .avatar {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 43.5%;
    top: 170px;
    background: #FFFFFF;
    border-radius: 50% !important;
}
.avatar2 {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 43.5%;
    top: 100px;
    background: #FFFFFF;
    border-radius: 50% !important;
} */
/* .avatar3 {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 44.5%;
    top: 230px;
    background: #FFFFFF;
    border-radius: 50% !important;
} */
.activeBtn {
    padding: 3px 0px !important;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
    width: 230px !important;
}
.provBtn {
    padding: 3px 0px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
    width: 230px !important;
}
.dialogBtn {
    padding: 3px 0px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
}
.dialogCancel {
    color: #954437 !important;
    width: 140px !important;
    height: 140px !important;
    margin: 15px;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
    /* border: 1px solid #C6C6C6 !important; */
    /* box-shadow: 3px 5px 9px rgba(0, 0, 0, 0.1) !important; */
    /* border-radius: 9px !important; */
    width: 760px !important;
    background: #FFFFFF !important;
    border: 0px solid #D8D8D8 !important;
    border-radius: 15px !important;
    position: unset !important;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    /* border: 1px solid #C6C6C6 !important;
    box-shadow: 3px 5px 9px rgba(0, 0, 0, 0.1) !important;
    border-radius: 9px !important;
    width: 760px !important; */
    width: 760px !important;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    border-radius: 15px !important;
    position: unset !important;
}


.css-mbdu2s {
    overflow: visible!important;
}
.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1000;
    position: relative;
    max-height: calc(100% - 64px);
    height: unset !important;
    max-width: 600px;
    width: calc(100% - 64px);
   
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px 10px 5px 10px !important;
}

.chargesTitle {
    color: #0E0E2C;    
    font-size: 15px;
}

.labelDialog {
    display: flex;
    align-items: center;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.selectDialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 15px 3px 6px;
    gap: 10px;
    width: 214px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #9A9A9A;
    border-radius: 9px;
}

.selectStyles {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('../../assets/icons/dialog.svg') no-repeat right;
    background-position: right 10px top 50%;
    padding-right: 30px;
    position: relative;
    margin-bottom: 0rem;
}

.closeBtn {
    background-color: #006A96 !important;
    border-radius: 9px !important;
    /* width: 340.5px !important; */
    height: 29px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 2px 25px !important;
    gap: 10px !important;
    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;
}

.refreshBtn {
    background-color: #7BBC9B !important;
    border-radius: 9px !important;
    /* width: 340.5px !important; */
    height: 29px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 2px 25px !important;
    gap: 10px !important;
    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;
}

.date-pick {
    color: #C6C6C6;
    height: 27px;
    /* width: 340.5px; */
    width: 100%;
    border: 1px solid #C6C6C6;
    border-radius: 9px;
    padding: 0px 6px;
    /* css to add calendar icon*/
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('../../assets/icons/calendar.svg') no-repeat right;
    background-position: right 10px top 50%;
    padding-right: 30px;
    position: relative;
    margin-bottom: 0rem;
}

.react-datepicker__month-container {
    float: left;
    background: #efefef !important;
    top: -18px !important;
}



.first-header {
    width: 165px;
}

.innerdata p {
    margin: 0px !important;
    line-height: 1rem;
}

.rule-table tr td:nth-child(1) {
    width: 75px;
}

.rule-table tr th:nth-child(3) {
    width: 100px;
}

.rule-table tr td:nth-child(3) {
    width: 100px;
}

.rule-table tr th:nth-child(5) {
    width: 130px;
}

.rule-table tr td:nth-child(5) {
    width: 130px;
}

.rule-table tr th:nth-child(6) {
    width: 100px;
}

.rule-table tr td:nth-child(6) {
    width: 100px;
}

.rule-table tr th:nth-child(10) {
    width: 120px;
    padding-right: 42px;
}

.rule-table tr td:nth-child(10) {
    width: 100px;
}

.rule-table tbody::-webkit-scrollbar {
    width: 15px;
}

/* rounded checkbox CSS here */
.round-check {
    position: relative;
    margin-left: 2rem;
}

.round-check label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round-check label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 15px;
}

.round-check input[type="checkbox"] {
    visibility: hidden;
}

.round-check input[type="checkbox"]:checked+label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round-check input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.foot-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dlock {
    font-size: 16px;
    margin-left: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.fontw {
    font-weight: 600;
    font-size: 18px;
    color: #0E0E2C;
}
.dateFont {
    color: #9B9B9B;
    font-weight: 300;
    font-size: 12px;
    /* font-size: 0.8rem; */
}

.fontL {
    font-size: 0.8rem !important;
}

.css-cwpu7v {
    overflow-y: unset !important;
    width: unset !important;
    border-radius: 9px !important;
}

.ruleStyle {
    height: 69%;
    text-align: left;
    background-color: #FAFAFA;
    border-radius: 9px;
    padding: 24px 7px;
    margin: 0.6rem;
}
.bottomDivider {
    border-bottom: 1px solid rgb(0, 128, 168);
    margin: 2px 4px 25px 4px;
}
.mrtop-55 {
    margin-top: 60px;
}

/* table border green on checkbox selection */
.tabl-with-border td:nth-child(1) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(2) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(3) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(4) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(5) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(6) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(7) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(8) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(9) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tabl-with-border td:nth-child(10) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:1px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

/* table border green on mark as reviewed field */
.tbl-with-border td:nth-child(1) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(2) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(3) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(4) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(5) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(6) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(7) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(8) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(9) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(10) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:0px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}
.tbl-with-border td:nth-child(11) .innerdata {
    border-top:1px solid #5CB02C;
    border-right:1px solid #5CB02C;
    border-bottom:1px solid #5CB02C;
    border-left:0px solid #5CB02C;
    border-radius: 0px 9px 9px 0px;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.table-row td:nth-child(11) .innerdata{
    border-radius: 0px 9px 9px 0px;
}

.encounter_count{
    color:#535353
}
.round-check {
    position: relative;
    margin-left: 2rem;
  }

  .round-check label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }
  .round-check label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 15px;
  }
  .round-check input[type="checkbox"] {
    visibility: hidden;
  }

  .round-check input[type="checkbox"]:checked + label {
    background-color: #5CB02C;
    border-color: #5CB02C;
  }
  .round-check input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  .foot-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dlock{
    font-size: 16px;
    margin-left: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}
.fontw{
    font-weight: 600;
    font-size: 18px;
}
.fontL{
    font-size: 0.8rem !important;
}
.avatar3 {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left:33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left:33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
}
.avatar2 {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left:33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.widthunset .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container{
    max-width: unset !important;
}


/* //09/02/2024 */
.m-none{
    margin:unset !important
}

/* 27/03/2024 */

.claims-modal-custom .css-cwpu7v{
    max-width: 759px;
    width: calc(100% - 64px) !important;
}



/* @media (max-width:1920px) {
    .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.75%;
        top: 260px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.75%;
        top: 185px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
} */

@media screen and (max-width:1800px) {
    .rule-table tbody {
        max-height: 52vh;
    }

    .ruleStyle {
        height: 66%;
        padding: 6px 7px;
    }

    .mrtop-55 {
        margin-top: 45px;
    }
    .rule-box1 {
        max-height: 220px;
        max-width: 260px;
    }

    .table-row:nth-child(odd) .innerdata {
        white-space: pre-wrap;
    }

    .table-row:nth-child(even) .innerdata {
        white-space: pre-wrap;
    }
    /* .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44%;
        top: 220px;
        background: #FFFFFF;
        border-radius: 50% !important;
       
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.25%;
        top: 150px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
    /* .avatar3 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44%;
        top: 190px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */

}

@media (min-width:1600px) {
    .first-header {
        width: 75px;
    }

    .custom-table th {
        padding-left: 20px !important;
    }
}

@media (max-width:1536px) {
    .rule-table tbody {
        max-height: 46vh;
    }

    .first-header {
        width: 5%;
    }

    .mrtop-55 {
        /* margin-top: -10px !important; */
        /* margin-top: 20px !important; */
        margin-top: -13px !important;
    }
    .ruleBtn{
        height: 30px;
    }

    .ruleStyle {
        height: 62%;
        padding: 6px 7px;
    }
    .rule-box1 {
        max-height: 200px;
        max-width: 260px;
    }
    /* .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 160px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 95px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
    /* .avatar3 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 140px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
}

@media (max-width:1500px) {
    .first-header {
        width: 5%;
    }

    .custom-table th {
        padding-left: 30px !important;
        color: #fff;
    }
}
