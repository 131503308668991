.option-list:hover {
    background-color: #cef8b687;
}

select:-internal-list-box option:checked {
    background-color: #cef8b6;
}
select:-internal-list-box option:active {
    background-color: #cef8b6;
}

.bg-active {
    background-color: #cef8b6;
}