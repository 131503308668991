.textInput {
  width: 100%;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  outline: none;
}

.textInputLabel {
    float: left;
    font-size: 16px;
    font-family: 'Futura PT'
}

.futuraPT {
  font-family: 'Futura PT'
}

.searchInputField {
    height: 30px;
    width: 270px;
    border: 1px solid #d8d8d8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 9px;
    outline: none;
    padding-left: 10px;
}



/* The checkbox-container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 310;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid transparent;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #5CB02C;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 10px;
    top: 4px;
    width: 9px;
    height: 17px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  