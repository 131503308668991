.text-lead {
  font-weight: 500 !important;
  font-size: 16px;
}

.trizetto-table-cell {
  background: white;
  /* Black to Grey/Stroke Grey */
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  height: 40px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
}

.trizetto-table-cell-active {
  background: #5cb02c;
  color: white;
}

.table-footer-text {
  font-size: 14px;
  font-weight: 400;
}

.state-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


/* change the select option background color  */

 .playerlist-datatable .MuiCard-root:nth-child(2) {
  height: 350px!important;
}

.playerlist-datatable table tbody {
  height: 300px;
}

