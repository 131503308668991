.contentUser {
  font-family: 'Futura PT';
  font-style: normal;
  font-size: 26px;
  line-height: 33px;
  margin-left: 94px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
}

.contentText {
    font-family: 'Futura PT';
    font-style: normal;
    font-size: 17px;
    letter-spacing: -0.02em;
}
.headingUserName {
  font-family: 'Futura PT';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 31px;
    margin-left: 21%;
    display: flex;
    text-wrap: nowrap;
}

.emailIcon{
  position: absolute;
  left: 23%;
  right: 10%;
  top: 40%;
  bottom: 15%;
}
.calenderIcon{
  position: absolute;
    left: 23%;
    right: 10%;
    /* top: 30%; */
    bottom: 29%;
}
.userCred {
  line-height: 30px;
}

.userCred span {
  margin-top: 0px !important;
  display: flex;
} 
.planContent_user {
    font-family: 'Futura PT' !important;
  }

.headingsText {
    list-style: none;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    /* display: flex; */
    align-items: center !important;
    text-align: center !important;
    letter-spacing: -0.02em;
}

.boxSizingDrop {
  padding: 0px 0px 10px;
  width: 400px;
  margin-left: 58px;
}


.Logo_name_user {
    width: 100%;
    height: 100%;
    display: table;
  }

.logoFieldUser {
    /* display: table-cell; */
    width: 90px;
    height: 90px;
    /* vertical-align: middle; */
}

.logoFieldUser p {
  background: #e5ebef;
  border-radius: 100%;
  border: 1.2px dashed #24476A;
  height: 90px;
  width: 90px;
  line-height: 85px;
  font-family: 'Futura PT';
  color: #24476A;
  font-size: 40px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
  margin-top: -25px;
}
.btnDiv{
  display: flex;
  margin-top: 60px;
  margin-left: -50px;
}

.deactivateBtn{
  padding: 3px 0px;
  gap: 10px;
  width: 100px;
  height: 30px;
  background: #3681A9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
  border: none;
  margin-top: -25px;
}

.deactivateBtn span{
font-size: 14px !important;
color: #FFFFFF;
}
.deleteBtn{
  padding: 3px 0px;
  gap: 10px;
  width: 100px;
  height: 30px;
  background: #00708C !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
  border: none;
  margin-top: -25px;
}

.deleteBtn span{
  color: #FFFFFF;
font-size: 14px !important;
}


/* ----------------- Dialog css ------------------ */
.MuiDialog-paper{
    /* height: 80vh !important; */
    border-radius: 15px !important;
}
.userTitle {
    height: 50px;
    width: 100%;
    background: linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%);
    border-radius: 15px 15px 0px 0px;
    color: #FFFFFF;
    font-size: 24px;
  }
  .userTitle .MuiDialogTitle-root {
    padding: 3px 0px;
}
  .userTitle span {
    font-weight: bold;
    font-size: 24px;
  }
  .planDesc {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #535353;
  }
  .userCard {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
  }

  .userCardHead {
    padding: 17px 10px 10px 20px !important;
    width: 100%;
    height: 40px !important;
    background: #24476A !important;
    border-radius: 15px 15px 0px 0px !important;
  }
  .userCardHead h5 {
    font-size: 20px;
  }
  .userContent {
    width: 100% !important;
    font-family: 'Futura PT' !important;
  }
  .planContentHeight{
    height: 400px;
  }
  .user-Input {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 10px;
    grid-gap: 5px;
    gap: 5px;
    width: 100%;
    height: 30px;
    /* background: #FFFFFF; */
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
  }
  .userLabel {
    font-weight: 400;
    font-size: 15px;
    margin-left: 60px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

.userCardContent {
  width: 550px !important;
  position: relative;
  font-family: 'Futura PT' !important;
  padding: 15px 15px 42px 15px;
  max-height: 190px;
}

.practice-search {
  border-radius: 9px !important;
  height: 30px;
  /* position: fixed; */
  background: #fff;
  z-index: 1;
  margin-top: -10px;
  margin-left: 0px;
  /* background: url('../../assets/icons/searchIcon.svg') no-repeat right; */
  background: url('../../../assets/icons/searchIcon.svg') no-repeat right;
  background-position: right 15px top 53%;
}

.practiceDrop{
  /* margin: 10px 0px -10px -8px; */
  /* border: 1px solid #CACACA; */
  border-radius: 9px;
  width: 520px;
  height: 150px;
  overflow-y: auto;
}

.optionPracDrop{
  display: flex;
  align-items: center;
  margin-left: 1px;
  margin-top: 3px;
  font-size: 15px;
}
.practiceText{
  margin-left: 20px;
}


.noRecordPrac {
  font-size: 16px;
  color: #954437;
  margin: 0% 0% 0% 36%;
}
.actionBtn {
  display: contents;
}
.pass-btn {
  position: absolute;
  cursor: pointer;
  right: 28px;
  margin-top: -22px;
}

.checkmark {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: white;
  border: 1.5px solid #D8D8D8;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.okBTN {
  background-color: #24476a !important;
  width: 65px !important;
  height: 35px;
  color: white !important;
  border-radius: 9px !important;
  margin-left: 450px !important;
  bottom: 15px;
}

.editButtonDiv{
    /* position: fixed; */
    bottom: 7px;
    width: 0%;
    position: absolute;
}

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}


@media screen and (max-width:1536px){
  .editButtonDiv{
     /* position: fixed; */
     bottom: 7px;
     width: 0%;
     position: absolute;
  }
}