/* remove arrow from number type */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.page-backGround{
    background-color: #10496a;
    height: 100vh;
}

.pageHeadings {
    color: #535353;
    font-size: 24px;
    margin-left: -15px;
}
.pageSubHeading {
    color: #535353;
    font-size: 16px;
    margin-left: -15px;
}
.inputFields {
    width: 375px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 5px 5px 5px 3px;
    padding-left: 6px;
}

.changePswrd{
    width: 375px;
    height: 40px;
    padding: 3px 0px;
    gap: 10px;
    background: #24476A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    border: none;
}

.changePswrd span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.11em;
    color: #FFFFFF;
}
.resendPswrd{
    width: 375px;
    height: 40px;
    padding: 3px 0px;
    gap: 10px;
    background: #00708C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    border: none;
}

.resendPswrd span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.11em;
    color: #FFFFFF;
}

.termsCondition p{
    width: 375px;
    height: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #535353;
    text-decoration: none;
}

.font-face-poppins{
    font-family: 'Poppins';
}
.loginCard  {
    background: #FFFFFF;
    box-shadow: 3px 3px 13px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 900px;
    height: 415px;
}
.text-align{
    text-align: left;
}
.h100{
    height: auto;
}
@media only screen and (device-width: 1368px) and (device-height: 912px) and (orientation : landscape) { 

    .h100 {
        height: 48%;
    }

   }
@media only screen and (device-width: 1280px) and (device-height: 800px) and (orientation : landscape) { 
    .h100 {
        height: 53%;
    }

}

@media only screen and (device-width: 1180px) and (device-height: 820px) and (orientation : landscape) { 
    .h100 {
        height: 51%;
    }

   }
   @media only screen and (device-width: 1024px) and (device-height: 768px) and (orientation : landscape) { 
    .h100 {
        height: 54%;
    }
  
   }
   @media screen and (max-width:912px){
    .text-align{
        text-align: left;
        width: 60%;
        margin: auto;
    }
    .loginCard  {
        width: 80%;
        margin: 0% 5%;
        height: 100%;
    }
    .customize-dflex .d-flex{
        justify-content: center;
    }
    .h100{
        height: 52%;
    }
   }
  @media screen and (max-width:820px){
    .text-align{
        text-align: left;
        width: 60%;
        margin: auto;
    }
    .loginCard  {
        width: 90%;
        margin: 0% 5%;
        height: 100%;
    }
    .customize-dflex .d-flex{
        justify-content: center;
    }
    .h100{
        height: 52%;
    }
  }
  @media screen and (min-width:320px) and (max-width:768px){
    .loginCard  {
        width: 90%;
        margin: 0% 5%;
        height: 100%;
    }
    .customize-dflex .d-flex{
        justify-content: center;
    }
    .text-align{
        text-align: left;
        width: 64%;
        margin: auto;
    }
    .h100{
        height: 58%;
    }
    
}