.chart {
    height: 50px !important;
    width: 50px !important;
}
.pie_value{
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 600;
    color: #858585;
    font-family: 'Futura PT';
}

.font-face-futura {
    font-family: 'Futura PT';
}