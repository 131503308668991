.setting-filter {
    position: absolute;
    top: 232px;
    overflow: hidden;
    height: 86px;
    width: 250px;
    border-radius: 9px;
    padding: 6px 10px 0px 10px;
    cursor: pointer;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    z-index: 9999;
}

