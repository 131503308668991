body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Futura PT";
  src: local("Futura PT"),
   url("./fonts/Futura/futura light bt.ttf") format("truetype");
}
@font-face {
  font-family: "Futura Medium PT";
  src: local("Futura Medium PT"),
   url("./fonts/Futura/futura medium bt.ttf") format("truetype");
}
@font-face {
  font-family: "Futura Book PT";
  src: local("Futura Book PT"),
   url("./fonts/Futura/Futura Book font.ttf") format("truetype");
}

@font-face {
  font-family: "Futura Bk BT";
  src: local("Futura Bk BT"),
   url("./fonts/Futura/FutuBk.ttf") format("truetype");
  /* font-weight: bold; */
}
  
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), 
  url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "P22UndergroundBook";
  src: local("P22UndergroundBook"), 
  url('./fonts/P22Underground/P22UndergroundBook.ttf') format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), 
  url('./fonts/Roboto/Roboto-Regular.ttf') format("truetype");
}

.text-right {
  text-align: right !important;
}

.height-auto {
  height: auto !important;
}

.text-futura {
  font-family: "Futura PT";
}