.alert-heading {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 64px;
  text-align: center;
  color: #535353;
}

.alert-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.alert-footer-text {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #535353;
}

.alert-icon-container {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  top: -1%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  overflow: visible;
  z-index: -1;
  text-align: center;
}

.alert-icon {
  height: 118px;
  margin-top: 33px;
}


/* 
.rolesalert {
  background-color: #ffffff!important;
  width: 100%!important;
  max-width: 760px;
} */