.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Futura PT';
  color: #535353;
  font-weight: bold;
}
.Mui-selected span.MuiTab-wrapper {
  color: #3A7CA5 !important;
}
.MuiIconButton-root{
  padding: 5px !important;
}
.PrivateTabIndicator-colorPrimary-15 {
  background-color: #3A7CA5 !important;
  color: #3A7CA5 !important;
}
.PrivateTabIndicator-root-14 {
  width: 100%;
  bottom: 0;
  height: 0.8px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.PrivateTabIndicator-colorPrimary-19 {
  background-color: #3A7CA5 !important;
  color: #3A7CA5 !important;
}
.PrivateTabIndicator-root-18 {
  height: 0.75px !important;
}

/* .setting-filter{
  width: 35px;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  cursor: pointer;
} */
.filter_col {
  display: flex;
  place-content: flex-end;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #9B9B9B;
  margin: 0px 40px 0px 0px;
}
.filter_drop {
  margin: 0px;
  height: 30px;
  width: 125px;
  padding: 1px 15px 5px 10px;
  background-color: #FFFFFF !important;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  background: url('../../assets/icons/drop.svg') no-repeat right;
  background-position: right 15px top 53%;
  font-size: 16px;
}

.filter_drop:focus {
    outline: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}



.setting-search {
  padding: 0px 10px;
  width: 285px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 9px;
  background: url('../../assets/icons/searchIcon.svg') no-repeat right;
  background-position: right 15px top 53%;
}
.setting-search:focus {
  outline: none;
}
.filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 28px;
}

.setting-table {
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 15px;
  padding: 0px;
}

.tables thead {
  padding: 10px 10px 10px 40px;
  gap: 10px;
  height: 50px;
  background-color: #24476A !important;
  border-radius: 15px 15px 0px 0px;
  color: #FFFFFF;
  position: relative;
  font-size: 20px;
}

.tables > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.tables thead, tbody, tfoot, tr, td, th {
  border-color: #e3e3e3;
  border-style: solid !important;
  border-width: 0px;
}

.tables tbody {
  display: block;
  overflow-y: auto;
}

.tables thead,
.tables tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.inviteBtn {
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: #24476A !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
  color: #FFFFFF !important;
}

.practiceTable thead {
  padding: 10px 10px 10px 40px;
  gap: 10px;
  height: 50px;
  background: #24476A;
  border-radius: 15px 15px 0px 0px;
  color: #FFFFFF;
  position: relative;
  font-size: 20px;
}

.practiceTable tbody {
  display: block;
  overflow-y: auto;
  height: 235px;
}

.practiceTable thead,
.practiceTable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.practiceTable tr th:nth-child(1){
  width: 60%;
}

.practiceTable tr th:last-child{
  width: 15%;
}
.practiceTable tr td:nth-child(1){
  width: 60%;
  /* padding-left: 20px; */
}

.practiceTable tr td:nth-child(4){
  width: 15%;
  padding-right: 19px;
}

/* .practiceTable tr td:nth-child(2){
  width: 20%;
} */
.practiceTable tr td:last-child{
  width: 15%;
  /* padding-right: 60px; */
}

.serviceTable thead {
  padding: 10px 10px 10px 40px;
  gap: 10px;
  height: 50px;
  background: #24476A;
  border-radius: 15px 15px 0px 0px;
  color: #FFFFFF;
  position: relative;
  font-size: 20px;
}

.serviceTable tbody {
  display: block;
  overflow-y: auto;
  height: 235px;
}

.serviceTable thead,
.serviceTable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.serviceTable tr th:nth-child(1){
  width: 80%;
}
.serviceTable tr td:nth-child(1){
  width: 85%;
  padding-left: 20px;
}
.serviceTable tr td:nth-child(2){
  width: 20%;
}
.providerName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 10px;
  gap: 5px;
  width: 100%;
  height: 30px;
  /* background: #EFEFEF; */
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
}
.providerId {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 10px;
  gap: 5px;
  width: 100%;
  height: 30px;
  /* background: #EFEFEF; */
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
}
.disable{
  background: #EFEFEF;
}

/* ------------------- link practice modal css ------------------- */
.resolvePM {
  color: #954437;
  cursor: pointer;
  font-weight: 600;
}
.linkBtnBefore{
  padding: 3px 0px;
  gap: 10px;
  /* width: 287px; */
  height: 50px;
  background: #6A7987 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}
.linkBtnAfter{
  padding: 3px 0px;
  gap: 10px;
  /* width: 287px; */
  height: 50px;
  background: #3681A9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}
.nevermindBtn {
  padding: 3px 0px;
  gap: 10px;
  /* width: 287px; */
  height: 50px;
  background: #954437 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}
.showButtonText{
  font-size: 25px;
  font-family: 'Futura PT';
  color: #535353;
  line-height: 0.5rem;
}
.searchAgain{
  padding: 3px 0px;
  gap: 10px;
  height: 39px;
  background: #00708C !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}
.verifyLink{
  padding: 3px 0px;
  gap: 10px;
  width: 350px;
  height: 40px;
  background: #3681A9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}

/* ------------------- practice css -------------- */

.card-content-width {
  width: 100%;
}
.practice-profile {
  font-family: 'Futura PT' !important;
  font-size: 15px !important;
  color: #0E0E2C;
}
.setting-link{
  text-align: left;
  font-family: 'Futura PT';
  color: #535353;
}
.info-box {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 100px;
  padding: 0px;
  gap: 10px;
  width: 93.5%;
  background: #FFFFFF !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
}

.Logo_name {
    width: 100%;
    height: 100%;
    display: table;
  }
  .logotext {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
  }
  .logotext p {
    background: #e5ebef;
    border-radius: 100%;
    border: 1.2px dashed #24476A;
    height: 90px;
    width: 90px;
    line-height: 2.8;
    font-family: 'Futura PT';
    color: #24476A;
    font-size: 30px;
    margin-bottom: 0;
  }
  .details_text_sec {
    text-align: left;
    font-family: 'Futura PT';
    padding-left: 2rem;
    line-height: 1rem;
  }
  .details_text_sec p:first-child {
    font-size: 24px;
    color: #0E0E2C;
  }
  .details_text_sec p:nth-child(2) {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .details_text_sec p:nth-child(3) {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .practiceEmailIcon {
    width: 20px;
    height: 17px;
    margin-right: 8px;
  }
  .details_text_sec p:last-child svg.MuiSvgIcon-root {
    font-size: 22px !important;
    margin-right: 8px;
  }
  .details_text_sec p:last-child {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .ptc{
    margin-top: 0.2rem;
  }
  .button_deactive {
    margin: auto;
    margin-bottom: 1rem;
    text-align: right;
  }
  .deactivateBtn {
    padding: 3px 0px;
    gap: 10px;
    width: 100px;
    height: 30px;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
  }
  .usersBtn {
    padding: 3px 0px;
    gap: 10px;
    width: 100px;
    height: 30px;
    background: #00708C !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
  }

  .profile-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 0px 100px;
    padding: 0px;
    gap: 10px;
    width: 93.5%;
    height: 345px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    border-radius: 15px !important;
  }
  .font20 {
    font-size: 20px !important;
  }
  .profile-box-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 10px 10px 10px 30px;
    gap: 10px;
    width: 100%;
    height: 40px;
    background: #24476A;
    border-radius: 15px 15px 0px 0px;
  }
  .practice-input {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 10px;
    gap: 5px;
    width: 100%;
    height: 30px;
    /* background: #FFFFFF; */
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
  }
  .practice-input:focus{
    outline: none !important;
  }
  .pracButtonDiv {
    display: flex;
    float: right;
  }
  .pracEditBtn {
    color: white !important;
    font-family: 'Futura PT';
    padding: 3px 0px;
    gap: 10px;
    width: 100px;
    height: 30px;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
  }
  .saveBtn {
    color: white !important;
    font-family: 'Futura PT';
    padding: 3px 0px;
    gap: 10px;
    width: 100px;
    height: 30px;
    background: #00708C !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
  }
  .rules-box {
    display: inline-table;
    margin-top: 35px;
    font-size: 17px;
    font-family: 'Futura PT';
    color: #535353;
  }
  .rules-box1 {
    display: inline-table;
    margin-top: 70px;
    font-size: 17px;
    font-family: 'Futura PT';
    color: #535353;
  }
  .font12 {
    font-size: 12px;
  }
  .practice-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 0px 100px;
    padding: 0px;
    gap: 10px;
    width: 93.5%;
    height: 330px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    border-radius: 15px !important;
  }
  .pracSettingDiv{
    display: flex;
    float: right;
    margin-top: 130px;
  }
  .practice-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 0px 100px;
    padding: 0px;
    gap: 10px;
    width: 93.5%;
    height: 621px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    border-radius: 15px !important;
  }
  .providerLabel {
    font-size: 15px;
    color: #0E0E2C;
    font-family: 'Futura PT';
    font-weight: 600;
  }
  .providerInput {
    padding: 7px 10px;
    width: 95%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
  }
  .px5{
    padding-left: 5rem;
  }

/* ------------------- group css ----------------- */

.addGroupTitle {
  height: 50px;
  width: 600px;
  background: linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%);
  border-radius: 15px 15px 0px 0px;
  color: #FFFFFF;
  font-size: 24px;
}
.addGroupTitle span {
  font-weight: bold;
  font-size: 24px;
}
.addGroupDesc {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535353;
  text-align: center;
}
.addGroupCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 10px;
  background: #FFFFFF !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  text-align: left;
}
/* .MuiPaper-elevation1 {
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
} */
.addGroupCardHead {
  padding: 17px 10px 10px 20px !important;
  width: 548px;
  height: 40px !important;
  background: #24476A !important;
  border-radius: 15px 15px 0px 0px !important;
}
.addGroupCardHead h5 {
  font-size: 20px;
}
.addGroupContent {
  width: 530px !important;
  font-family: 'Futura PT' !important;
}
.cardLabel {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #0E0E2C;
}
.csvDiv {
  text-align: right;
  color: #5CB02C;
  font-size: 12px;
}
.actionButton {
  display: flex;
  width: 100%;
}
.nevermind {
  padding: 3px 0px;
  gap: 10px;
  width: 287px;
  height: 50px;
  background: #954437 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}
.looksgood {
  padding: 3px 0px;
  gap: 10px;
  width: 287px;
  height: 50px;
  background: #3681A9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}

.text {
  height: 30px;
  width: 100%;
  border: 1px solid #D8D8D8;
  border-radius: 9px;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  color: #535353;
}
.text:focus {
  outline: none;
}
.modifier-search{
  border-radius: 9px !important;
  height: 30px;
  /* position: fixed; */
  background: #fff;
  z-index: 1;
  margin-top: -10px;
  margin-left: -8px;
  background: url('../../assets/icons/drop.svg') no-repeat right;
  background-position: right 15px top 53%;
}
  .MuiCardContent-root:last-child {
    padding-bottom: 6px;
    /* padding-bottom: 16px; */
  }

  .check{
    color: #5CB02C !important;
  }
  .drop{
    margin: 10px 0px -10px 0px;
    border: 1px solid #CACACA;
    font-family: 'Futura PT';
    border-radius: 9px;
    width: 520px;
    height: 150px;
    overflow-y: auto;
  }
  /* .groupDrop {
    margin: 10px 0px -10px -8px !important;
    border: 1px solid #CACACA !important;
    border-radius: 9px !important;
    width: 535px !important;
    height: 150px !important;
    padding: 8px 0px 8px 6px !important;
    overflow-y: auto;
  } */

  .optionDrop{
      display: flex;
      align-items: center;
      margin-left: 1px;
      margin-top: 3px;
      font-size: 15px;
  }
  
  .drops{
    border-radius: 9px;
    width: 520px;
    height: 150px;
    overflow-y: auto;
  }

  .checkmark {
    position: absolute;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1.5px solid #D8D8D8;
  }
  
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  /* .okUserPracBtn{
    background-color: #24476a !important;
    width: 65px !important;
    height: 35px;
    color: white !important;
    border-radius: 9px !important;
    margin-left: 450px !important;
    bottom: 15px;
  } */
  .userButtonDiv{
    position: absolute;
    bottom: 0px;
    margin-left: -28px;
    width: 0%;
  }

  .button{
    background-color: #24476a !important;
    width: 65px !important;
    height: 35px;
    color: white !important;
    border-radius: 9px !important;
    margin-left: 450px !important;
    bottom: 15px;
  }

  .buttonDiv{
    position: absolute;
    bottom: 10px;
    width: 0%;
    margin-left: -20px;
  }
  .spantext{
    margin-left: 20px;
  }
  .noRecord {
    font-size: 16px;
    color: #954437;
  }
.prc-scroll{
  max-height: 500px;
  overflow-y: scroll; 
  width: 100%;
  max-width: 45%;
}
.setting-table tbody tr{
  line-height: 1.7rem !important;
}
.setting-table tbody tr td{
  vertical-align: middle;
}
.setting-table tbody tr td button span img{
  width: 20px;
  height: 20px;
}
.css-mbdu2s{
  border-radius: 15px !important;
}
.css-ohyacs{
  padding: 5px 18px !important;
}
.jss15 {
  /* background-color: #3A7CA5 !important; */
  background-color: #3A7CA5;
  color: #3A7CA5 !important;
}
.jss23 {
  /* background-color: #3A7CA5 !important; */
  background-color: #3A7CA5;
  color: #3A7CA5 !important;
}
/* .jss22 {
  height: 0.75px !important;
} */
/* .jss19 {
  background-color: #3A7CA5 !important;
  color: #3A7CA5 !important;
} */
.jss18 {
  height: 0.75px;
}
.custom_switch .MuiSwitch-thumb {
  width: 10px;
  height: 10px;
  background-color:  #7BBC9B;
}
.custom_switch .MuiSwitch-switchBase {
  top: 8px;
  left: 11px;
}
.custom_switch .MuiSwitch-track{
  background-color: #fff;
  border: 3px solid #7BBC9B;
  height: 19px;
  opacity: 1;
  border-radius: 9px;
}
.custom_switch .MuiSwitch-root {
  padding: 9px 12px;
  width: 63px;
  position: relative;
}
.custom_switch .jss18 {
    height: auto !important;
}

.custom_switch .jss19 {
  background-color: none !important;
  color: none !important;
}
.setting-table .table>:not(:first-child) {
  border-top: 0px solid !important;
}
.MuiTabs-indicator {
  background-color: #4083b2;
}
.custom_switch .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  /* background-color: #d4d4d4; */
  background-color: white;
  border: 3px solid #7BBC9B;
  opacity: 1;

}
.custom_switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  /* background-color: #d4d4d4; */
  background-color: white;
  border: 3px solid #7BBC9B;
  opacity: 1;
}
table.table-borderless.wdt {
  width: 480px;
}
table.table-borderless.rules-wdt{
  width: 710px;
}
.mxTo{
  margin-left: 0.3rem;
}
.prc_select{
  width: 145px;
  height: 36px;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
}
.prc_select:focus-visible{
  outline: none;
}
.rules-wdt > :not(caption) > * > * {
  border-bottom-width: 1px;
}
.prc_select { /*container for custom dropdown arrow*/
  -webkit-appearance: none;
  /* -moz-appearance: window; */
  padding: 2px 10px 2px 10px;
  background-image: url('../../assets/icons/drop.svg');
  background-repeat: no-repeat;
  background-position: 93% 50%;
}
.prc_select2{
  width: 300px;
  height: 36px;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
}
.prc_select2:focus-visible{
  outline: none;
}
.rules-wdt > :not(caption) > * > * {
  border-bottom-width: 1px;
}
.prc_select2 { /*container for custom dropdown arrow*/
  -webkit-appearance: none;
  /* -moz-appearance: window; */
  padding: 2px 10px 2px 10px;
  background-image: url('../../assets/icons/drop.svg');
  background-repeat: no-repeat;
  background-position: 93% 50%;
}
img.form-btn {
  position: absolute;
  right: -14px;
  top: 26px;
}
.cpass-btn {
  position: absolute;
  right: 20px;
  top: 26px;
}
.crpass{
  width: 95% !important;
}
img.linkStatus {
  width: 20px;
  margin-right: 0.3rem;
}

.editPracticeUser {
  width: 100%;
}


/* 02/21/2024 */


.claims-modal .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
  max-width: 760px!important;
}

.claims-modal .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
  padding: 10px;
  margin: 0px;
 
}


.claims-modal .css-ypiqx9-MuiDialogContent-root {
  padding: 0px 10px;
  overflow: hidden;
}


/* 03/14/2024 */

:is(.addGroupModal , .margin-0px ) .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  margin-inline: 0px;
}

.addGroupModal .groupDrop {
  height:100px;
  overflow: auto;
}

:is(.addGroupModal , .margin-0px ) .modifier-search {
margin: 0px;
}

.addGroupModal .addGroupContent , .margin-0px .drop {
  width: 100%!important;
} 

.addGroupTitle h2 {
  margin-top: 8px;
}

.margin-0px .addGroupContent { 
  position: relative;
}

.margin-0px .buttonDiv {
  bottom: 27px;
  right: 92px;
}
.margin-0px .button {
  margin-left: 0px!important;
  bottom: 0px;
}


  @media screen and (max-width:1920px){
    .modifier-search {   
      width: 535px !important;
      max-width: 837px;
    }
    :is(.addGroupModal , .margin-0px)  .modifier-search {
      width: 100% !important;     
      max-width: 837px;
    }
  }
  @media screen and (max-width:1800px){
    .practice-info-box, .practice-box, .profile-box, .info-box {
      width: 92.5%;
    }
    .details_text_sec {
      padding-left: 2.7rem;
    }
  }
  @media screen and (max-width:1536px){
    .details_text_sec {
      padding-left: 3.5rem;
  }
  .providerInput {
    width: 93%;
  }
  .practice-info-box, .practice-box, .profile-box, .info-box {
    width: 92%;
  }
  }
@media screen and (min-width:1536px) and (max-width:1800px){
  .prc-scroll{
    max-height: 500px;
    overflow-y: scroll; 
    width: 100%;
    max-width: 55%;
  }
  .ps-5 {
    padding-left: 5rem !important;
  }
}