.ro-btn {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
    border-radius: 9px !important;
}

.ro-btn-primary {
    background-color: #24476a !important;
}

.ro-btn-success {
    background-color: #297200 !important;
}

.ro-btn-info-light {
    background-color: #00708C !important;
}

.ro-btn-info {
    background-color: #3681A9 !important;
}

.ro-btn-danger {
    background-color: #954437 !important;
}